import { gql } from "@apollo/client"

import {
  UserDataFragment,
  AddressFragment,
  ProductFramgnet,
  CategoriesFragment,
  CartFragment,
  ProductDetailFragment,
} from "./fragments"

export const Q_AUTH_INITIAL = gql`
   query getMyData($lang: String!) {
      me: me {
         ${UserDataFragment}
      }
      addresses: addresses {
         ${AddressFragment}
      }
      wishlists: wishlists(language: $lang) {
         ${ProductFramgnet}
      }
      categories: categories(filter: { language: $lang }) {
         ${CategoriesFragment}
      }
      carts: carts(language: $lang) {
         ${CartFragment}
      }
   }
`

export const Q_GUEST_INITIAL = gql`
   query getMyGuestData($lang: String!) {
      categories: categories(filter: { language: $lang }) {
         ${CategoriesFragment}
      }
      carts: carts(language: $lang) {
         ${CartFragment}
      }
   }
`

//!Get MyData
export const GET_MY_DATA = gql`
  query getMyData($lang: String!) {
    me: me {
      id
      name
      email
      gender
      dob
      point
      created_at
      updated_at
      email_verified_at
    }
    # wishlist: wishlists(language: $lang) {
    #   id
    # }
    categories: categories(filter: { language: $lang }) {
      id
      title
      slug
      children {
        id
        slug
        title
        children {
          id
          title
          slug
        }
      }
    }
    addresses: addresses {
      id
      name
      email
      phone
      address
      country
      state
      city
      subdistrict
      postcode
      is_primary
      longitude
      latitude
      geolabel
      notes
    }
    carts: carts(language: $lang) {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        stock
        sell_price
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const Q_LOCATION = gql`
  query LOCATION($s: String!) {
    searchCitySubdistrict(s: $s) {
      city
      id
      subdistrict
      postcodes
      state
    }
  }
`

export const Q_ADDRESSES = gql`
  query ADDRESS {
    addresses {
      id
      name
      email
      phone
      address
      country
      state
      city
      subdistrict
      postcode
      is_primary
      longitude
      latitude
      geolabel
      notes
    }
  }
`

export const Q_CATEGORIES = gql`
  query CATEGORIES($lang: String!) {
    categories(filter: { language: $lang }) {
      id
      title
      slug
      image
      description
      seo {
        meta_title
        meta_keyword
        meta_description
      }
      children {
        id
        slug
        title
        image
        children {
          id
          title
          slug
          image
        }
      }
    }
  }
`

export const Q_CATALOGS = gql`
  query PRODUCTS(
    $lang: String!
    $is_featured: Int
    $is_new: Int
    $limit: Int
    $sort: String
  ) {
    catalogs(
      filter: {
        language: $lang
        is_featured: $is_featured
        is_new: $is_new
        limit: $limit
        sort: $sort
      }
    ) {
      brands {
        id
        title
        slug
        is_featured
        image
        description
        seo {
          meta_title
          meta_keyword
          meta_description
        }
      }
      products {
        id
        title
        images
        manage_stock
        price
        sell_price
        slug
        is_wishlist
        is_new
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
        secondcategory {
          title
          id
        }
        skus {
          id
          stock
          image_no
          option_detail_key1
          option_detail_key2
          sku_code
        }
      }
    }
  }
`

export const Q_SEARCH_CATALOGS = gql`
  query SEARCH_CATALOGS(
    $s: String
    $page: Int
    $limit: Int
    $lang: String!
    $sort: String
  ) {
    catalogs(
      filter: {
        language: $lang
        s: $s
        limit: $limit
        page: $page
        sort: $sort
      }
    ) {
      products {
        id
        title
        images
        manage_stock
        price
        sell_price
        slug
        is_wishlist
        is_new
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
        secondcategory {
          title
          id
        }
        skus {
          id
          stock
          image_no
          option_detail_key1
          option_detail_key2
          sku_code
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
    }
  }
`

export const Q_PRODUCT = gql`
  query PRODUCT($id: ID!, $lang: String!) {
    product(language: $lang, id: $id) {
      id
      title
      description
      images
      manage_stock
      price
      sell_price
      slug
      skus {
        id
        option_detail_key1
        option_detail_key2
        sku_code
        stock
        price
        sell_price
      }
      relateds {
        id
        title
        images
        sell_price
        firstcategory {
          id
          title
        }
      }
      firstcategory {
        title
        slug
        id
      }
      secondcategory {
        title
        slug
        id
      }
      thirdcategory {
        title
        slug
        id
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_STOCK = gql`
  query STOCK($id: ID!) {
    stock(sku_id: $id) {
      stock
      status
      message
      price
      sell_price
    }
  }
`

export const Q_MY_CART = gql`
  query CARTS($coupon: [String], $lang: String!) {
    carts(language: $lang, coupon: $coupon) {
      grand_total
      availableCoupons {
        code
        discount_type
        value
        minimum_purchase
        is_applied
        auto_apply
        description
      }
      applied_codes
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        stock
        sell_price
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const Q_SHIPPING = gql`
  query SHIPPING_METHOD(
    $shipping_postcode: String!
    $shipping_subdistrict: String!
    $shipping_city: String!
    $shipping_country: String!
  ) {
    shippingmethods(
      shipping: {
        shipping_postcode: $shipping_postcode
        shipping_subdistrict: $shipping_subdistrict
        shipping_country: $shipping_country
        shipping_city: $shipping_city
      }
    ) {
      id
      price
      service_name
      insurance_fee
      name
    }
  }
`

export const Q_FAQ = gql`
  query FAQ($s: String) {
    faqs(language: "en", s: $s) {
      id
      title
      description
    }
  }
`

export const Q_PAYMENT_METHODS = gql`
  query PAYMENT_METHOD {
    paymentmethods {
      title
      fee_pct
      fee_flat
      logo
    }
  }
`

export const Q_MY_ORDERS = gql`
  query MY_ORDERS($page: Int, $lang: String!) {
    myorders(language: $lang, limit: 999, page: $page) {
      datas {
        id
        order_no
        total_amount
        shipping_fee
        discount_ongkir
        total_discount
        insurance
        tax
        unique_code
        payment_service_fee
        payment_method
        currency
        exchange_rate
        final_amount
        charge_service_fee
        pay_with_point
        billing {
          id
          name
          phone
          email
          address
          country
          state
          city
          subdistrict
          postcode
        }
        shipping {
          id
          name
          phone
          email
          address
          country
          state
          city
          subdistrict
          postcode
          tracking_number
        }
        order_status {
          id
          title
        }
        details {
          title
          image
          sku_code
          sku_id
          price
          discount
          discount_coupon
          quantity
          notes
          option_1 {
            title
            value
          }
          option_2 {
            title
            value
          }
        }
        payment_method
        tracking_logs {
          created_at
          message
        }
        created_at
        updated_at
        status
        message
        payment_info {
          status
          account_number
          checkout_url
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
    }
  }
`

export const Q_COUNTRIES = gql`
  query Country {
    countries {
      id
      name
      code
    }
  }
`

export const Q_ORDER_DETAILS = gql`
  query ORDER_DETAIL($id: ID!, $lang: String!) {
    order(language: $lang, id: $id) {
      id
      order_no
      total_amount
      shipping_fee
      discount_ongkir
      total_discount
      insurance
      tax
      unique_code
      payment_service_fee
      currency
      exchange_rate
      final_amount
      charge_service_fee
      pay_with_point
      billing {
        name
        phone
        email
        address
        country
        state
        city
        subdistrict
        postcode
      }
      shipping {
        id
        name
        phone
        email
        address
        country
        state
        city
        subdistrict
        postcode
        shipping_method
        tracking_number
      }
      order_status {
        id
        title
      }
      details {
        title
        image
        sku_code
        price
        discount
        discount_coupon
        quantity
        notes
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      payment_method
      tracking_logs {
        created_at
        message
      }
      created_at
      updated_at
      status
      message
      notes
      payment_info {
        status
        account_number
        checkout_url
      }
    }
  }
`

export const Q_MY_CATALOGS = gql`
  query MY_CATALOGS($option: String, $value: String) {
    catalogs(
      filter: {
        language: "en"
        page: 1
        limit: 8
        options: { option: $option, value: $value }
      }
    ) {
      products {
        id
        title
        images
        manage_stock
        price
        sell_price
        is_wishlist
        is_new
        relateds {
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_PAGES = gql`
  query GET_PAGES($lang: String!) {
    pages(language: $lang) {
      slug
      title
      id
      content
    }
  }
`

export const Q_CART_WITH_SHIPPING = gql`
  query CARTS(
    $lang: String!
    $delivery_id: Int!
    $postcode: String!
    $coupon: [String]
    $city: String!
    $subdistrict: String!
    $country: String!
  ) {
    carts(
      language: $lang
      coupon: $coupon
      shipping: {
        method_id: $delivery_id
        postcode: $postcode
        city: $city
        country: $country
        subdistrict: $subdistrict
      }
    ) {
      grand_total
      subtotal
      availableCoupons {
        code
        discount_type
        value
        minimum_purchase
        is_applied
        auto_apply
        description
      }
      applied_codes
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        sell_price
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const Q_MY_CATALOGS3 = gql`
  query MY_CATALOGS1(
    $category_id: Int
    $price: String
    $page: Int
    $options: [OptionFilter]
  ) {
    catalogs(
      filter: {
        language: "en"
        page: $page
        limit: 9
        category_id: $category_id
        price: $price
        options: $options
      }
    ) {
      products {
        id
        title
        images
        manage_stock
        price
        sell_price
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_MY_CATALOGS2 = gql`
  query MY_CATALOGS(
    $category_id: Int
    $price: String
    $page: Int
    $options: [OptionFilter]
  ) {
    catalogs(
      filter: {
        language: "en"
        page: $page
        limit: 9
        category_id: $category_id
        price: $price
        options: $options
      }
    ) {
      products {
        id
        title
        images
        manage_stock
        price
        sell_price
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_CATALOGS_BY_CATEGORY = gql`
  query MY_CATALOGS(
    $lang: String!
    $category_id: Int
    $price: String
    $page: Int
    $options: [OptionFilter]
    $limit: Int
    $sort: String
    $is_new: Int
    $sale: Int
  ) {
    catalogs(
      filter: {
        language: $lang
        page: $page
        limit: $limit
        category_id: $category_id
        price: $price
        options: $options
        sort: $sort
        is_new: $is_new
        sale: $sale
      }
    ) {
      brands {
        id
        title
        slug
        is_featured
        image
        description
        seo {
          meta_title
          meta_keyword
          meta_description
        }
      }
      products {
        id
        title
        images
        manage_stock
        price
        sell_price
        slug
        is_wishlist
        is_new
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
        secondcategory {
          title
          id
        }
        skus {
          id
          stock
          image_no
          option_detail_key1
          option_detail_key2
          sku_code
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`
export const Q_MY_WISHLIST = gql`
  query WISHLIST($lang: String!) {
    wishlists(language: $lang) {
      id
      title
      slug
      images
      manage_stock
      price
      sell_price
      is_wishlist
      is_new
      firstcategory {
        title
        id
      }
      secondcategory {
        title
        id
      }
      thirdcategory {
        title
        id
      }
      skus {
        id
        option_detail_key1
        option_detail_key2
        sku_code
        stock
        price
        sell_price
        image_no
        stock
      }
    }
  }
`

export const Q_BANNERS = gql`
  query BANNER($lang: String!, $type: String!) {
    banners(language: $lang, type: $type) {
      title
      id
      title
      desktop
      mobile
      url
      background_type
    }
  }
`

export const Q_FAQ_CAT = gql`
  query FAQ($lang: String!) {
    faqCategories(language: $lang) {
      id
      title
    }
  }
`

export const Q_FAQ_DESC = gql`
  query FAQDESC($lang: String!, $s: String) {
    faqs(language: $lang, s: $s) {
      title
      description
      category {
        id
        title
      }
    }
  }
`

export const Q_STORE_INFO = gql`
  query Q_STORE_INFO($lang: String!) {
    storeInfo(language: $lang) {
      directory
      additionalInfo
      openingHours
    }
  }
`

export const Q_OUTLET = gql`
  query Q_OUTLET {
    outlets {
      id
      image
      title
      address
      state
      city
      subdistrict
      postcode
      phone
      longitude
      latitude
      label
    }
  }
`

// import { gql } from "@apollo/client";

// const loginFragment = `... on AuthPayload {
//   access_token
//   refresh_token
// }`

// //!FRAGMENT
// const ProductFragment = `...on [Product] {
//   id
//   title
//   slug
//   images
//   manage_stock
//   price
//   sell_price
//   is_wishlist
//   firscategory
//   secondcategory
//   thirdcategory
//   skus
// }`

// //!Get MyData
// export const GET_MY_DATA = gql`
// query getMyData($lang: String!) {
//   me: me {
//     id
//     name
//     email
//     gender
//     dob
//     point
//     created_at
//     updated_at
//     # phone
//     email_verified_at
//   }
//   wishlist: wishlists(language: $lang) {
//     id
//   }
//   categories: categories(filter: {language: $lang}) {
//     id
//     title
//     slug
//     children {
//       id
//       slug
//       title
//       children {
//         id
//         title
//         slug
//       }
//     }
//   }
//   addresses: addresses {
//       id
//       name
//       email
//       phone
//       address
//       country
//       state
//       city
//       subdistrict
//       postcode
//       is_primary
//       longitude
//       latitude
//       geolabel
//   }
//   carts: carts(language: $lang) {
//       grand_total
//       subtotal
//       discount_coupon
//       weight
//       volume
//       realweight
//       realvolume
//       finalweight
//       realfinalweight
//       point
//       shipping_fee
//       tax
//       free_shipping
//       discount_ongkir
//       insurance_fee
//       free_shipping_value
//       must_insurance
//       items {
//         id
//         title
//         image
//         product_id
//         sku_id
//         quantity
//         price
//         sell_price
//         option_1 {
//           title
//           value
//         }
//         option_2 {
//           title
//           value
//         }
//       }
//     status
//     message
//   }
// }
// `

// export const Q_LOCATION = gql`
//   query LOCATION($s: String!){
//   searchCitySubdistrict(s: $s) {
//     city
//     id
//     subdistrict
//     postcodes
//     state
//   }
// }
// `

// export const Q_ADDRESSES = gql`
//   query ADDRESS {
//     addresses {
//       id
//       name
//       email
//       phone
//       address
//       country
//       state
//       city
//       subdistrict
//       postcode
//       is_primary
//       longitude
//       latitude
//       geolabel
//     }
//   }
// `

// // export const Q_CATEGORIES = gql`
// //   query CATEGORIES($lang: String!) {
// //   categories(filter:{language:$lang}) {
// //     id
// //     title
// //     slug
// //     children {
// //       id
// //       slug
// //       title
// //       children {
// //         id
// //         title
// //         slug
// //       }
// //     }
// //   }
// // }
// // `

// export const Q_CATALOGS = gql`
//   query CATALOGS($lang: String!, $is_featured: Int) {
//     catalogs(filter: {language: $lang, is_featured: $is_featured}) {
//         products {
//           id
//           title
//           images
//           manage_stock
//           price
//           sell_price
//           slug
//           is_wishlist
//           relateds {
//             title
//           }
//           firstcategory{
//             id
//             title
//           }
//           secondcategory {
//             title
//             id
//           }
//           skus{
//             id
//             stock
//             image_no
//             option_detail_key1
//             option_detail_key2
//             sku_code
//           }
//       }
//     }
//   }
// `

// export const Q_SEARCH_CATALOGS = gql`
// query SEARCH_CATALOGS($s: String, $page: Int) {
//   catalogs(filter: {language: "en", s: $s, limit: 9, page: $page}) {
//     products {
//       id
//       title
//       images
//       manage_stock
//       price
//       sell_price
//       relateds {
// 				title
//       }
//       firstcategory{
//         id
//         title
//       }
//     }
//     pagination{
//       count
//       current_page
//       total_data
//       last_page
//     }
//   }
// }
// `

// export const Q_PRODUCT = gql`
//   query PRODUCT($id: ID!, $lang: String!) {
// 	product(language: $lang, id: $id) {
//   	id
//     title
//     description
//     images
//     manage_stock
//     price
//     sell_price
//     slug
//     skus {
//       id
//       option_detail_key1
//       option_detail_key2
//       sku_code
//       stock
//       price
//       sell_price
//     }
//     relateds {
//       id
//       title
//       images
//       sell_price
//       firstcategory{
//         id
//         title
//       }
//     }
//     firstcategory{
//       id
//       title
//     }
//   	options {
//       title
//     	values {
//         title
//         value
//       }
//     }
// 	}
// }
// `

// export const Q_STOCK = gql`
//   query STOCK($id: ID!) {
//   stock(sku_id: $id) {
//     stock
//     status
//     message
//     price
//     sell_price
//   }
// }
// `

// export const Q_MY_CART = gql`
//   query CARTS($coupon: String){
//   carts(
//     language: "en",
//     coupon: $coupon
//   ) {
//       grand_total
//       subtotal
//       discount_coupon
//       weight
//       volume
//       realweight
//       realvolume
//       finalweight
//       realfinalweight
//       point
//       shipping_fee
//       tax
//       free_shipping
//       discount_ongkir
//       insurance_fee
//       free_shipping_value
//       must_insurance
//       items {
//         id
//         title
//         image
//         product_id
//         sku_id
//         quantity
//         price
//         sell_price
//         option_1 {
//           title
//           value
//         }
//         option_2 {
//           title
//           value
//         }
//       }
//     status
//     message
//   }
// }
// `

// export const Q_SHIPPING = gql`
//   query SHIPPING_METHOD(
//     $shipping_postcode: String!
//     $shipping_subdistrict: String!
//     $shipping_city: String!
//     $shipping_country: String!
//   ) {
//   shippingmethods(shipping: {
//     shipping_postcode: $shipping_postcode,
//     shipping_subdistrict: $shipping_subdistrict,
//     shipping_country: $shipping_country,
//     shipping_city: $shipping_city
//     }) {
//     id
//     price
//     service_name
//     insurance_fee
//     name
//   }
// }`

// export const Q_FAQ = gql`
// query FAQ($s: String){
//   faqs(language: "en", s: $s){
//     id
//     title
//     description
//   }
// }
// `

// export const Q_PAYMENT_METHODS = gql`
//   query PAYMENT_METHOD {
//   paymentmethods {
//     title
//     fee_pct
//     fee_flat
//     logo
//   }
// }
// `

// export const Q_MY_ORDERS = gql`
// query MY_ORDERS($page: Int) {
//   myorders(language:"en", limit: 10, page: $page) {
//   	datas {
// 			id
//       order_no
//       total_amount
//       shipping_fee
//       discount_ongkir
//       total_discount
//       insurance
//       tax
//       unique_code
//       payment_service_fee
//       payment_method
//       currency
//       exchange_rate
//       final_amount
//       charge_service_fee
//       pay_with_point
//       billing {
//         id
//         name
//         phone
//         email
//         address
//         country
//         state
//         city
//         subdistrict
//         postcode
//       }
//       shipping {
//         id
//         name
//         phone
//         email
//         address
//         country
//         state
//         city
//         subdistrict
//         postcode
//         tracking_number
//       }
//       order_status {
//         id
//         title
//       }
//       details {
//         title
//         image
//         sku_code
//         price
//         discount
//         discount_coupon
//         quantity
//         notes
//         option_1 {
//           title
//           value
//         }
//         option_2 {
//           title
//           value
//         }
//       }
//       payment_method
//       tracking_logs {
//         created_at
//         message
//       }
//       created_at
//       updated_at
//       status
//       message
//       payment_info {
//         status
//         account_number
//         checkout_url
//       }
//     }
//     pagination {
//       count
//       current_page
//       total_data
//       last_page
//     }
//   }
// }
// `

// export const Q_COUNTRIES = gql`
//   query Country {
//   countries {
//     id
//     name
//     code
//   }
// }
// `

// export const Q_ORDER_DETAILS = gql`
//   query ORDER_DETAIL($id: ID!) {
//   order(language: "en", id:$id) {
//     id
//     order_no
//     total_amount
//     shipping_fee
//     discount_ongkir
//     total_discount
//     insurance
//     tax
//     unique_code
//     payment_service_fee
//     currency
//     exchange_rate
//     final_amount
//     charge_service_fee
//     pay_with_point
//     billing {
//       name
//       phone
//       email
//       address
//       country
//       state
//       city
//       subdistrict
//       postcode
//     }
//     shipping {
//       id
//       name
//       phone
//       email
//       address
//       country
//       state
//       city
//       subdistrict
//       postcode
//       shipping_method
//       tracking_number
//     }
//     order_status {
//       id
//       title
//     }
//     details {
//       title
//       image
//       sku_code
//       price
//       discount
//       discount_coupon
//       quantity
//       notes
//       option_1 {
//         title
//         value
//       }
//       option_2 {
//         title
//         value
//       }
//     }
//     payment_method
//     tracking_logs {
//       created_at
//       message
//     }
//     created_at
//     updated_at
//     status
//     message
//     payment_info {
//       status
//       account_number
//       checkout_url
//     }
//   }
// }
// `

// export const Q_MY_CATALOGS = gql`
// query MY_CATALOGS($option: String, $value: String) {
//   catalogs(filter: {
//     language: "en",
//     page: 1,
//     limit: 8,
//     options: {
//       option: $option,
//       value: $value
//     }
//   }) {
//     products {
//       id
//       title
//       images
//       manage_stock
//       price
//       sell_price
//       is_wishlist
//       relateds {
// 				title
//       }
//     }
//     pagination {
//       count
//       current_page
//       total_data
//       last_page
//     }
//     options {
//       title
//       values {
//         title
//         value
//       }
//     }
//   }
// }
// `

// export const Q_PAGES = gql`
// query GET_PAGES {
//   pages(language:"en") {
//     slug
//     title
//     seo {
//       meta_title
//       meta_description
//       meta_keyword
//     }
//     content
//   }
// }`

// export const Q_CART_WITH_SHIPPING = gql`
//   query CARTS(
//     $delivery_id: Int!,
//     $postcode: String!,
//     $coupon: String,
//     $city: String!,
//     $subdistrict: String!,
//     $country: String!,
//   ){
//   carts(
//     language: "en",
//     coupon: $coupon
//     shipping: {
//       method_id: $delivery_id,
//       postcode: $postcode,
//       city: $city,
//       country: $country,
//       subdistrict: $subdistrict
//     }
//   ) {
//       grand_total
//       subtotal
//       discount_coupon
//       weight
//       volume
//       realweight
//       realvolume
//       finalweight
//       realfinalweight
//       point
//       shipping_fee
//       tax
//       free_shipping
//       discount_ongkir
//       insurance_fee
//       free_shipping_value
//       must_insurance
//       items {
//         id
//         title
//         image
//         product_id
//         sku_id
//         quantity
//         price
//         sell_price
//         option_1 {
//           title
//           value
//         }
//         option_2 {
//           title
//           value
//         }
//       }
//     status
//     message
//   }
// }
// `

// export const Q_MY_CATALOGS3 = gql`
// query MY_CATALOGS1(
//   $category_id: Int
//   $price: String
//   $page: Int
//   $options: [OptionFilter]
// ) {
//   catalogs(filter: {
//     language: "en",
//     page: $page,
//     limit: 9,
//     category_id: $category_id
//     price: $price
//     options: $options
//   }) {
//     products {
//       id
//       title
//       images
//       manage_stock
//       price
//       sell_price
//       relateds {
// 				title
//       }
//       firstcategory{
//         id
//         title
//       }
//     }
//     pagination {
//       count
//       current_page
//       total_data
//       last_page
//     }
//     options {
//       title
//       values {
//         title
//         value
//       }
//     }
//   }
// }
// `

// export const Q_MY_CATALOGS2 = gql`
// query MY_CATALOGS(
//   $category_id: Int
//   $price: String
//   $page: Int
//   $options: [OptionFilter]
// ) {
//   catalogs(filter: {
//     language: "en",
//     page: $page,
//     limit: 9,
//     category_id: $category_id
//     price: $price
//     options: $options
//   }) {
//     products {
//       id
//       title
//       images
//       manage_stock
//       price
//       sell_price
//       relateds {
// 				title
//       }
//       firstcategory{
//         id
//         title
//       }
//     }
//     pagination {
//       count
//       current_page
//       total_data
//       last_page
//     }
//     options {
//       title
//       values {
//         title
//         value
//       }
//     }
//   }
// }
// `

// export const Q_MY_WISHLIST = gql`
//   query WISHLIST($lang: String!) {
//   wishlists(language: $lang) {
//       id
//       title
//       slug
//       images
//       manage_stock
//       price
//       sell_price
//       is_wishlist
//       firstcategory {
//         title
//         id
//       }
//       secondcategory {
//         title
//         id
//       }
//       thirdcategory {
//         title
//         id
//       }
//         skus {
//           id
//           option_detail_key1
//           option_detail_key2
//           sku_code
//           # stock
//           price
//           sell_price
//           image_no
//         }
//       }
//   }
// `
