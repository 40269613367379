export const SET_USER_DATA = "SET_USER_DATA"
export const SET_AUTH_DATA = "SET_AUTH_DATA"
export const SET_ADDRESS = "SET_ADDRESS"
export const SET_ORDER = "SET_ORDER"
export const SET_MY_ORDERS = "SET_MY_ORDERS"
export const SET_XENDIT_FUNCTION = "SET_XENDIT_FUNCTIONS"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const SET_WISHLIST = "SET_WISHLIST"
export const SET_WISHLIST_DATA = "SET_WISHLIST_DATA"
export const SET_BACKGROUND_TYPE = "SET_BACKGROUND_TYPE"
export const SET_MESSAGE = "SET_MESSAGE"
export const SET_LOCATIONS = "SET_LOCATIONS"

// cart
export const SET_CART_LIST = "SET_CART_LIST"
export const SET_CARTS = "SET_CARTS"
export const SET_IS_DRAWER = "SET_IS_DRAWER"
export const SET_ADD_CART = "SET_ADD_CART"
export const SET_PRODUCT_ADDED = "SET_PRODUCT_ADDED"

// product
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST"
export const SET_PRODUCT_DETAIL = "SET_PRODUCT_DETAIL"
export const SET_CATALOGS = "SET_CATALOGS"
export const SET_CATALOGS_FEATURED = "SET_CATALOGS_FEATURED"

// general
export const SET_TOAST = "SET_TOAST"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_TOAST_HEIGHT = "SET_TOAST_HEIGHT"
export const SET_LOCALE = "SET_LOCALE"
export const SET_LOCALES = "SET_LOCALES"

// journal
export const SET_JOURNAL = "SET_JOURNAL"

// toast
export const SET_SHOW_TOAST = "SET_SHOW_TOAST"
export const SET_TYPE_TOAST = "SET_TYPE_TOAST"
export const SET_TEXT_TOAST = "SET_TEXT_TOAST"

// modal login
export const SET_SHOW_LOGIN = "SET_SHOW_LOGIN"
