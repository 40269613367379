import { combineReducers } from 'redux';
import authReducer from './authReducer';
// import cartReducer from './cartReducer';
// import productReducer from './productReducer';
import generalReducer from './generalReducer';
// import journalReducer from './journalReducer';

const appReducer = combineReducers({
   general: generalReducer,
   auth: authReducer,
   // cart: cartReducer,
   // product: productReducer,
   // general: generalReducer,
   // journal: journalReducer,
})
const rootReducer = (state, action) => {
   if (action.type === 'USER_LOGOUT') {
      return appReducer(undefined, action)
   }

   return appReducer(state, action)
}

export default rootReducer;