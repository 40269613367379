import {
  SET_LOCALE,
  SET_LOCALES,
  SET_CATEGORIES,
  SET_SHOW_TOAST,
  SET_TYPE_TOAST,
  SET_TEXT_TOAST,
  SET_SHOW_LOGIN,
  SET_BACKGROUND_TYPE,
  SET_LOCATIONS,
} from "../types"

const initialState = {
  locale: null,
  locales: null,
  categories: null,
  showToast: false,
  typeToast: null,
  textToast: "",
  showLogin: null,
  banner_type: "",
  locations: null,
}

const reducer = (state = initialState, action) => {
  let { type, payload } = action

  switch (type) {
    case SET_LOCALE:
      return { ...state, locale: payload }
    case SET_LOCALES:
      return { ...state, locales: payload }
    case SET_CATEGORIES:
      return { ...state, categories: payload }
    case SET_SHOW_TOAST:
      return { ...state, showToast: payload }
    case SET_TYPE_TOAST:
      return { ...state, typeToast: payload }
    case SET_TEXT_TOAST:
      return { ...state, textToast: payload }
    case SET_SHOW_LOGIN:
      return { ...state, showLogin: payload }
    case SET_BACKGROUND_TYPE:
      return { ...state, banner_type: payload }
    case SET_LOCATIONS:
      return { ...state, locations: payload }
    default:
      return state
  }
}

export default reducer
