export const UserDataFragment = `
   id
   name
   email
   gender
   dob
   created_at
   updated_at
   email_verified_at
   phone
`

export const AddressFragment = `
   id
   name
   email
   phone
   address
   country
   state
   city
   subdistrict
   postcode
   is_primary
   longitude
   latitude
   geolabel
   notes
`

export const ProductFramgnet = `
   id
   title
   slug
   images
   manage_stock
   price
   sell_price
   is_wishlist
   is_new
`

export const ProductDetailFragment = `
   id
   title
   slug
   images
   manage_stock
   price
   sell_price
   is_wishlist
   is_new
   description
   specification
   options {
      title
      values {
        title
        value
      }
   }
   skus {
      id
      price
      sell_price
      sku_code
      stock
      option_detail_key1
      option_detail_key2
   }
   relateds {
      id
      title
      images
      sell_price
      price
      is_wishlist
   }
`

export const CategoriesFragment = `
   id
   title
   slug
   image
   children {
      id
      slug
      title
      image
      children {
         id
         title
         slug
         image
      }
   }
`

export const CartFragment = `
      grand_total
      subtotal
      availableCoupons {
        code,
        discount_type,
        value,
        minimum_purchase
        is_applied
        auto_apply
        description
      }
      applied_codes
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        stock
        sell_price
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
`
