import { useEffect, useState } from "react"
import { staticApollo } from "lib/apollo"
import { TokenValidation } from "lib/function/token-validation"
import { useDispatch } from "react-redux"
import { useRouter } from "next/router"
import LottieNagarey from "../../../assets/lottie/nagarey.json"

import {
  SET_USER_DATA,
  SET_CARTS,
  SET_ADDRESS,
  SET_WISHLIST,
  SET_CATEGORIES,
  SET_WISHLIST_DATA,
} from "lib/redux/types"

import { Q_AUTH_INITIAL, Q_GUEST_INITIAL } from "lib/graphql"

export default function LoadingScreen() {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const Router = useRouter()

  const handleGetInitData = async () => {
    const { token, user_status } = await TokenValidation()
    const client = staticApollo(token)
    let locale = Router.locale
    // USER
    if (user_status) {
      let { data: initData, errors } = await client.query({
        query: Q_AUTH_INITIAL,
        variables: { lang: locale },
        errorPolicy: "all",
      })
      if (initData) {
        let { me, addresses, wishlists, categories, carts } = initData
        dispatch({ type: SET_USER_DATA, payload: me })
        dispatch({ type: SET_CARTS, payload: carts })
        dispatch({ type: SET_ADDRESS, payload: addresses })
        dispatch({ type: SET_WISHLIST, payload: wishlists?.length })
        dispatch({ type: SET_WISHLIST_DATA, payload: wishlists })
        dispatch({ type: SET_CATEGORIES, payload: categories })
      }
      setLoading(false)
    } else {
      let { data: initData, errors } = await client.query({
        query: Q_GUEST_INITIAL,
        variables: { lang: locale },
        errorPolicy: "all",
      })
      if (initData) {
        let { carts, categories } = initData
        dispatch({ type: SET_CARTS, payload: carts })
        dispatch({ type: SET_CATEGORIES, payload: categories })
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetInitData()
  }, [Router.locale])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNagarey,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return null

  // return (
  //   <div className="loading-screen">
  //     <Lottie options={defaultOptions} height={500} width={500} />
  //   </div>
  // )
}

//   React.useEffect(handleGetAllLocation, [])
