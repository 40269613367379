//Types
import {
  SET_USER_DATA,
  SET_ADDRESS,
  SET_CARTS,
  SET_MY_ORDERS,
  SET_WISHLIST,
  SET_MESSAGE,
  SET_ADD_CART,
  SET_PRODUCT_ADDED,
  SET_WISHLIST_DATA,
} from "../types"

const initialState = {
  user_data: null,
  addresses: [],
  carts: null,
  my_orders: [],
  wishlist: 0,
  wishlist_data: [],
  message: "HELLO FROM REDUCER",
  add_cart: false,
  product_added: null,
}

const reducer = (state = initialState, action) => {
  let { type, payload } = action
  switch (type) {
    case SET_USER_DATA:
      return { ...state, user_data: payload }
    case SET_ADDRESS:
      return { ...state, addresses: payload }
    case SET_CARTS:
      return { ...state, carts: payload }
    case SET_MY_ORDERS:
      return { ...state, my_orders: payload }
    case SET_WISHLIST:
      return { ...state, wishlist: payload }
    case SET_WISHLIST_DATA:
      return { ...state, wishlist_data: payload }
    case SET_MESSAGE:
      return { ...state, message: payload }
    case SET_ADD_CART:
      return { ...state, add_cart: payload }
    case SET_PRODUCT_ADDED:
      return { ...state, product_added: payload }
    default:
      return state
  }
}

export default reducer
