import React from "react"
import "assets/scss/main.scss"
import "react-datepicker/dist/react-datepicker.css"

import { LoadingProvider } from "context/loading-context"
import { useStore } from "lib/redux"
import { Provider } from "react-redux"
import { useApollo } from "lib/apollo"
import { ApolloProvider } from "@apollo/client"
import Cookies from "js-cookie"
import LoadingScreen from "@components/global/loading-screen/loading-screen"

const checkToken = () => {
  let access = Cookies.get(`${process.env.ACCESS_TOKEn}`)
  let guest = Cookies.get(`${process.env.GUEST_TOKEN}`)
  if (access) {
    return access
  } else {
    if (guest) return guest
    else return process.env.INIT_TOKEN
  }
}

export default function MyApp({ Component, pageProps, token }) {
  const client = useApollo(pageProps.initialApolloState, checkToken())
  const store = useStore()
  return (
    <LoadingProvider>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <div className="app-wrapper">
            <LoadingScreen />
            <Component {...pageProps} />
          </div>
        </ApolloProvider>
      </Provider>
    </LoadingProvider>
  )
}

// MyApp.getInitialProps = async (appContext) => {
//   let appProps = await App.getInitialProps(appContext)
//   let { token } = await TokenValidation(appContext?.ctx)
//   return { ...appProps, token }
// }
