import { gql } from "@apollo/client"

const loginFragment = `... on AuthPayload {
  access_token
  refresh_token
}`

const productDetailsFragment = ``

const coreImage = `... on CoreImageBlock {
  attributes {
    ... on CoreImageBlockAttributes {
      id
      url
      alt
      height
      width
      className
    }
  }
}`

//!Refresh Token
export const REFRESH_TOKEN = gql`
  mutation REFRESH_TOKEN($refresh_token: String) {
    refreshToken(input: { refresh_token: $refresh_token }) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`

// access_token
// refresh_token
// expires_in
//       user {
//   name
//   email
// }

//!Login User
export const M_LOGIN = gql`
  mutation LOGIN($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_in
      user {
        name
        email
      }
    }
  }
`

//!Logout
export const M_LOGOUT = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`

//!Register User
export const M_REGISTER = gql`
  mutation register(
    $name: String!
    $email: String!
    $dob: Date!
    $password: String!
    $password_confirmation: String!
  ) {
    register(
      input: {
        name: $name
        email: $email
        gender: "male"
        password: $password
        password_confirmation: $password_confirmation
        dob: $dob
        is_subscribe: 0
        phone: "0811"
      }
    ) {
      tokens {
        user {
          id
          name
          email
          created_at
          updated_at
        }
      }
      status
      message
    }
  }
`

//!Forgot Password
export const M_FORGOT_PASSWORD = gql`
  mutation FORGOTPASSWORD($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
      message
    }
  }
`

//!Update User
export const M_UPDATE_USER = gql`
  mutation UPDATE_USER($name: String!, $dob: String!) {
    updateUser(input: { name: $name, dob: $dob, phone: "0", gender: "male" }) {
      status
      message
    }
  }
`

export const M_UPDATE_PASSWORD = gql`
  mutation CHANGE_PASSWORD(
    $old_password: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updatePassword(
      input: {
        old_password: $old_password
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`

//!ADD_NOTIFY
export const M_ADD_NOTIFY = gql`
  mutation ADD_NOTIFY($email: String!, $sku_id: ID!) {
    addToNotify(email: $email, sku_id: $sku_id) {
      status
      message
    }
  }
`

//!UPDATE CART
export const M_UPDATE_QTY_CART = gql`
  mutation UPDATE_CART($id: ID!, $quantity: Int!) {
    updateCart(id: $id, quantity: $quantity, language: "en") {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        sell_price
        stock
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

//!CREATE UPDATE ADDRESS
export const M_CREATE_UPDATE_ADDRESS = gql`
  mutation UPDATE_CART(
    $address_id: Int
    $name: String!
    $email: String!
    $phone: String!
    $address: String!
    $country: String!
    $state: String!
    $city: String!
    $subdistrict: String!
    $postcode: String!
    $is_primary: Int
    $geolabel: String
    $longitude: String
    $latitude: String
    $notes: String
  ) {
    createUpdateAddress(
      input: {
        address_id: $address_id
        name: $name
        email: $email
        phone: $phone
        address: $address
        country: $country
        state: $state
        city: $city
        subdistrict: $subdistrict
        postcode: $postcode
        is_primary: $is_primary
        geolabel: $geolabel
        longitude: $longitude
        latitude: $latitude
        notes: $notes
      }
    ) {
      id
      name
      email
      phone
      address
      country
      state
      city
      subdistrict
      postcode
      is_primary
      longitude
      latitude
      geolabel
      notes
    }
  }
`

//!DELETE ADDRESS
export const M_DELETE_ADDRESS = gql`
  mutation DELETE_ADDRESS($id: ID!) {
    deleteAddress(id: $id) {
      status
    }
  }
`

export const M_CONTACT_US = gql`
  mutation CONTACT(
    $name: String!
    $email: String!
    $message: String!
    $topic: String!
  ) {
    contactForm(
      input: { name: $name, email: $email, message: $message, topic: $topic }
    ) {
      status
      message
    }
  }
`

export const M_ADD_CART = gql`
  mutation ADD_CART($sku_id: ID!, $quantity: Int!, $lang: String!) {
    addToCart(sku_id: $sku_id, language: $lang, quantity: $quantity) {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        sell_price
        stock
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const M_DELETE_CART_ITEM = gql`
  mutation DELET_CART($id: ID!, $lang: String!) {
    deleteCart(id: $id, language: $lang) {
      status
      message
    }
  }
`

export const M_CREATEA_ORDER = gql`
  mutation CREATE_ORDER(
    $coupon: [String]
    $use_point: Int
    $address: AddressInput!
    $shipping_method_id: Int!
    $payment_method: String!
    $source: OrderSource
    $ref: String
    $ewallet_phone: String
    $credit_card: CreditPayment
    $notes: String
  ) {
    createOrder(
      language: "en"
      coupon: $coupon
      shipping: $address
      billing: $address
      payment_method: $payment_method
      source: $source
      ref: $ref
      ewallet_phone: $ewallet_phone
      credit_card: $credit_card
      use_point: $use_point
      shipping_method: { shipping_method_id: $shipping_method_id }
      notes: $notes
    ) {
      id
      order_no
      total_amount
      shipping_fee
      total_discount
      tax
      total_amount
      message
      status
      payment_info {
        status
        account_number
        checkout_url
        snap_token
      }
      final_amount
      currency
    }
  }
`

export const M_VERIFY_EMAIL = gql`
  mutation {
    verify {
      status
      message
    }
  }
`

export const M_FORGOTTEN_PASSWORD = gql`
  mutation FORGOT(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`

export const M_VERIFIED_EMAIL = gql`
  mutation VERIFY_EMAIL($id: Int!, $hash: String!) {
    verifyEmail(input: { id: $id, hash: $hash }) {
      token_type
      access_token
      access_token
      expires_in
      user {
        id
        name
        email
        gender
        dob
        point
        created_at
        updated_at
        phone
        email_verified_at
      }
    }
  }
`

export const M_ADD_WISHLIST = gql`
  mutation ADDWISHLIST($product_id: ID!) {
    addToWishlist(product_id: $product_id) {
      status
      message
    }
  }
`

export const M_CONFIRM_PAYMENT = gql`
  mutation CONFIRM_PAYMENT(
    $order_no: String!
    $account_name: String!
    $payment_bank: String!
    $payment_value: Int!
    $payment_date: String!
    $file: Upload
  ) {
    confirmPayment(
      input: {
        order_no: $order_no
        account_name: $account_name
        payment_bank: $payment_bank
        payment_value: $payment_value
        payment_date: $payment_date
        file: $file
      }
    ) {
      status
      message
    }
  }
`

export const M_CONTACT_FORM = gql`
  mutation CONTACT(
    $name: String!
    $email: String!
    $message: String!
    $topic: String!
  ) {
    contactForm(
      input: { name: $name, email: $email, message: $message, topic: $topic }
    ) {
      status
      message
    }
  }
`
export const M_NEW_PASSWORD = gql`
  mutation FORGOTTEN_PASSWORD(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`

export const M_REMIND_ME = gql`
  mutation REMINDME($sku_id: ID!, $email: String!) {
    addToNotify(sku_id: $sku_id, email: $email) {
      status
      message
    }
  }
`

export const M_REPAYMENT = gql`
  mutation REPAY($lang: String!, $order_id: Int!, $payment_method: String!) {
    repayment(
      language: $lang
      order_id: $order_id
      payment_method: $payment_method
    ) {
      payment_info {
        status
        account_number
        checkout_url
        snap_token
      }
    }
  }
`

export const M_CREATE_ORDER_WITH_BILLING = gql`
  mutation CREATE_ORDER_WITH_BILLING(
    $lang: String!
    $coupon: [String]
    $use_point: Int
    $address: AddressInput!
    $billing_address: AddressInput!
    $shipping_method_id: Int!
    $payment_method: String!
    $source: OrderSource
    $ref: String
    $ewallet_phone: String
    $credit_card: CreditPayment
    $notes: String
  ) {
    createOrder(
      language: $lang
      coupon: $coupon
      shipping: $address
      billing: $billing_address
      payment_method: $payment_method
      source: $source
      ref: $ref
      ewallet_phone: $ewallet_phone
      credit_card: $credit_card
      use_point: $use_point
      shipping_method: { shipping_method_id: $shipping_method_id }
      notes: $notes
    ) {
      id
      order_no
      total_amount
      shipping_fee
      total_discount
      tax
      total_amount
      message
      status
      payment_info {
        status
        account_number
        checkout_url
        snap_token
      }
      final_amount
      currency
    }
  }
`
